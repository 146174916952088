import React          from 'react';
import { Flex, Box }  from '@chakra-ui/react';
import { injectIntl } from 'react-intl';

import Wrapper        from '@interness/web-core/src/components/structure/Wrapper/Wrapper';
import Spacer         from '@interness/web-core/src/components/structure/Spacer/Spacer';
import Heading        from '@interness/web-core/src/components/text/Heading/Heading';
import ContactSidebar from '@interness/web-core/src/components/structure/ContactSidebar/ContactSidebar';
import SEO            from '@interness/web-core/src/components/modules/SEO/SEO';
import ContactForm    from '@interness/web-core/src/components/modules/ContactForm/ContactForm';
import { Link }       from '@interness/web-core/src/components/elements';
import ContactMap     from '@interness/web-core/src/components/media/ContactMap';


const ContactPage = props => {
  const intl = props.intl.formatMessage;

  return (
    <>
      <SEO title={'Kontakt'}/>
      <Wrapper>
        <Spacer/>
        <Heading>Kontakt</Heading>
        <Spacer height={20}/>

        <Flex flexWrap="wrap">
          <Box width={['100%', '50%', '50%', '50%']}>
            <h3>Info</h3>
            <ContactSidebar/>
            <Spacer height={20}/>
          </Box>
          <Box width={['100%', '50%', '50%', '50%']}>
            <ContactForm/>
          </Box>
        </Flex>
        <Spacer/>
        <div>
          <p>
            {intl({ id: 'pages.contact.privacyApproval1' })}
            <Link
              to={intl({ id: 'pages.contact.privacyLink' })}>{intl({ id: 'pages.contact.privacyLinkName' })}</Link>
            {intl({ id: 'pages.contact.privacyApproval2' })}
          </p>
        </div>
        <Spacer/>
      </Wrapper>
      <ContactMap/>
    </>
  )
};

export default injectIntl(ContactPage);
