import React, { useEffect, useState }             from 'react';
import PropTypes                                  from 'prop-types';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import { Icon }                                   from 'leaflet';
import { OpenStreetMapProvider }                  from 'leaflet-geosearch';
import styled                                     from '@emotion/styled';

import marker from './images/FaMapMarker.svg';
import './styles.css';

import { Link } from '../../elements';

const Container = styled.div`
    position: relative;
    width: 100%;
    padding-top: 50%;

    .leaflet-container {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
    }
`;

const LeafletMap = ({ address, name, options, showRoute }) => {
  const [coordinates, setCoordinates] = useState(null);
  const formattedAddress = `${address.street} ${address.city} ${address.zip_code} ${address.country}`;
  const escapedAddress = encodeURIComponent(`${address.street}+${address.city}+${address.zip_code}+${address.country}`);

  const mapOptions = {
    ...options,
    scrollWheelZoom: false
  }

  const getCoordinatesFromAddress = async () => {
    const provider = new OpenStreetMapProvider();
    let result;
    try {
      result = await provider.search({ query: formattedAddress });
    } catch (e) {
      console.log('Address not found', e)
    }
    if (result) {
      setCoordinates(result[0])
    }
  }

  useEffect(() => {
    getCoordinatesFromAddress();
    //eslint-disable-next-line
  }, [])

  if (typeof window !== 'undefined' && coordinates) {
    const MapIcon = new Icon({
      iconUrl: marker,
      iconSize: [35, 35],
      iconAnchor: [0, 0],
      popupAnchor: [0, 0]

    })
    return (
      <Container>
        <MapContainer {...mapOptions} center={{ lat: coordinates.y, lng: coordinates.x }} zoom={16}>
          <TileLayer
            attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          <Marker icon={MapIcon} position={{ lat: coordinates.y, lng: coordinates.x }}>
            <Popup>
              <div>
                <address>
                  {name && <p>{name}</p>}
                  <p>{address.street}, {address.city} {address.zip_code}</p>
                </address>
                {showRoute &&
                  <Link external to={`https://www.google.com/maps/dir/?api=1&destination=${escapedAddress}`}>Route
                    planen</Link>}
              </div>
            </Popup>
          </Marker>
        </MapContainer>
      </Container>
    )
  } else {
    return null;
  }
};

LeafletMap.propTypes = {
  name: PropTypes.string,
  options: PropTypes.object,
  showRoute: PropTypes.bool,
  address: PropTypes.shape({
    street: PropTypes.string.isRequired,
    city: PropTypes.string.isRequired,
    zip_code: PropTypes.string.isRequired,
    country: PropTypes.string.isRequired,
  })
}

LeafletMap.defaultProps = {
  showRoute: false,
}

export default LeafletMap;
