import React                       from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import LeafletMap from './LeafletMap/LeafletMap';

const ContactMap = () => {
  const data = useStaticQuery(query);
  const address = data.directusCompany.addresses[0];
  const addrStruc = {
    street: `${address.street} ${address.number.split('-')[0]}`,
    city: address.city,
    zip_code: address.zip_code,
    country: address.country,
  }
  return (
    <LeafletMap address={addrStruc} name={data.directusCompany.display_name} showRoute/>
  )
};

const query = graphql`
    query {
        directusCompany {
            display_name
            addresses {
                city
                country
                number
                street
                zip_code
            }
        }
    }
`;

export default ContactMap;
